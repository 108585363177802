import React from 'react'
import Layout from 'components/layout'
import { Helmet } from 'react-helmet'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import { graphql } from 'gatsby'
import Content from 'components/category/content'

const items = [
  {
    link: '/stihl/multi-task-tools/professional-kombisystem/',
    imageId: 'professionalKombisystemImage',
    name: 'Professional KombiSystem',
    alt: 'STIHL Professional KombiSystem',
  },
  {
    link: '/stihl/multi-task-tools/homeowner-kombisystem/',
    imageId: 'homeownerKombisystemImage',
    name: 'Homeowner KombiSystem',
    alt: 'STIHL Homeowner KombiSystem',
  },
  {
    link: '/stihl/multi-task-tools/battery-kombisystem/',
    imageId: 'batteryKombisystemImage',
    name: 'Battery KombiSystem',
    alt: 'STIHL Battery KombiSystem',
  },
  {
    link: '/stihl/multi-task-tools/kombisystem-attachments/',
    imageId: 'kombisystemAttachmentsImage',
    name: 'KombiSystem Attachments',
    alt: 'STIHL KombiSystem Attachments',
  },
  {
    link: '/stihl/multi-task-tools/yard-boss/',
    imageId: 'yardBossImage',
    name: 'Yard Boss',
    alt: 'STIHL Yard Boss',
  },
  {
    link: '/stihl/multi-task-tools/yard-boss-attachments/',
    imageId: 'yardBossAttachmentsImage',
    name: 'Yard Boss Attachments',
    alt: 'STIHL Yard Boss Attachments',
  },
  {
    link: '/stihl/multi-task-tools/yard-boss-accessories/',
    imageId: 'yardBossAccessoriesImage',
    name: 'Yard Boss Accessories',
    alt: 'STIHL Yard Boss Accessories',
  },
]

const MultiTaskToolsPage = ({ data }) => {
  const { allHutsonPromotion, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  const floaterItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>STIHL Multi-Task Tools | Hutson Inc</title>
        <meta
          name='description'
          content='Maximize your efficiency with STIHL Yard Boss and KombiSystem multi-task tools, giving you the flexibility to do edging, trimming, cultivating, and much more.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'STIHL',
                'item': 'https://www.hutsoninc.com/stihl/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Multi-Task Tools',
                'item': 'https://www.hutsoninc.com/stihl/multi-task-tools/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='STIHL Multi-Task Tools' />

      <Content>
        <Floater items={floaterItems} />

        <Promos data={promos} type='STIHL Multi-Task Tool' />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query multiTaskToolsStihlQuery($category: String = "multi-task-tools") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $category }, type: { eq: "stihl" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    heroImage: file(relativePath: { eq: "stihl/multi-task-tools/multi-task-tools-bg.jpg" }) {
      ...FullWidthImage
    }
    professionalKombisystemImage: file(
      relativePath: { eq: "stihl/multi-task-tools/professional-kombisystem.jpg" }
    ) {
      ...FloatingGridImage
    }
    batteryKombisystemImage: file(
      relativePath: { eq: "stihl/multi-task-tools/battery-kombisystem.jpg" }
    ) {
      ...FloatingGridImage
    }
    homeownerKombisystemImage: file(
      relativePath: { eq: "stihl/multi-task-tools/homeowner-kombisystem.jpg" }
    ) {
      ...FloatingGridImage
    }
    kombisystemAttachmentsImage: file(
      relativePath: { eq: "stihl/multi-task-tools/kombisystem-attachments.jpg" }
    ) {
      ...FloatingGridImage
    }
    yardBossImage: file(relativePath: { eq: "stihl/multi-task-tools/yard-boss.jpg" }) {
      ...FloatingGridImage
    }
    yardBossAttachmentsImage: file(
      relativePath: { eq: "stihl/multi-task-tools/yard-boss-attachments.jpg" }
    ) {
      ...FloatingGridImage
    }
    yardBossAccessoriesImage: file(
      relativePath: { eq: "stihl/multi-task-tools/yard-boss-accessories.jpg" }
    ) {
      ...FloatingGridImage
    }
  }
`

export default MultiTaskToolsPage
